import { Button, Modal, Spin, Table, message } from "antd";
import React, { useEffect, useState } from "react";
import { CgLivePhoto } from "react-icons/cg";
import { FaArrowRight } from "react-icons/fa";
import { Link } from "react-router-dom";
import FullLoader from "../../../components/FullLoader";
import { TbProgress } from "react-icons/tb";
import CountdownTimer from "./CountDown";

export default function ProcessingTable({ count }) {
  const [show, setShow] = useState(false);
  const [rolePlay, setrolePlay] = useState();
  const [live, setLive] = useState("pending");
  const [agent_id, setAgentId] = useState("");
  const [loading, setLoading] = useState(false);
  const [isStatus, setIsStatus] = useState();
  const [pageNo, setPageNo] = useState(1);
  const [totalPages, setTotalPages] = useState();
  const [callAPI, setCallAPI] = useState(1);

  useEffect(() => {
    if (show) {
      setLoading(true);
      getrolePlay();
    }
  }, [show, pageNo, callAPI]);

  const getrolePlay = () => {
    const u = JSON.parse(window.localStorage.getItem("user"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed?page=${pageNo}&limit=6&sort_by=${-1}&status=processing&user_id=${
        u?.user_id
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setrolePlay(data.feedList);
          setTotalPages(data.totalPages);
        }
      });
  };

  const gamingColumns = [
    {
      title: "Name",
      dataIndex: "title",
      key: "title",
      width: 200,

      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Link",
      dataIndex: "link",
      key: "link",

      render: (text, row) => (
        <div style={{width:"100px"}} className="link-click-cs">
          <a target="blank" href={text}>
            {text}{" "}
          </a>
        </div>
      ),
    },
    {
      title: "Platform",
      dataIndex: "platform",
      key: "platform",
      render: (text, row) => <b>{text} </b>,
    },
    {
      title: "Instruction",
      dataIndex: "instruction",
      key: "instruction",
      render: (text, row) => (
        <div style={{ maxHeight: "190px", overflowY: "auto" }}>{text} </div>
      ),
    },
    {
      title: "Completion In",
      dataIndex: "till",
      key: "till",
      render: (text, row) => (
        <div style={{ width: "100px",}}>
          <CountdownTimer targetDate={text} />{" "}
        </div>
      ),
    },

    {
      title: "",
      dataIndex: "stream_url",
      key: "stream_url",

      render: (text, row) =>
        isStatus === row._id ? (
          <Spin />
        ) : (
          <button
            className="my-lbutton table-lb-btn red-lb"
            size="small"
            type="primary"
            onClick={() => {
              setIsStatus(row._id);
              updatePlay({ status: "canceled" }, row._id);
            }}
          >
            Cancel
          </button>
        ),
    },
  ];
  const updatePlay = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setIsStatus();
        if (response && response.isPatched) {
          let all = rolePlay.filter((f) => f._id !== id);
          setrolePlay(all);
        } else {
          message.error("something went wrong");
        }
      });
  };
  return (
    <>
      <button
        onClick={() => {
          if (count?.processing > 0) {
            setShow(true);
          }
        }}
        className={`my-lbutton fle-x-c ${
          count?.processing > 0 ? " orange-my-lb " : " red-my-lb "
        }`}
      >
        <TbProgress className="ani-for-cirlce" /> Processing{" "}
        <span className="circle-nmbr">{count?.processing}</span>
        <FaArrowRight className="arrow-right-c" />
      </button>
      <Modal
        width={1000}
        open={show}
        onCancel={() => {
          setShow();
          setPageNo(1);
          setTotalPages();
          setrolePlay();
        }}
        className="no-buttons-modal black-modal top-20-x"
      >
        <h4 style={{ textAlign: "center", marginBottom: "20px" }}>
          Moderated Sessions
        </h4>
        <div style={{ minHeight: "300px" }}>
          {loading ? (
            <FullLoader />
          ) : (
            <Table
              className="scrol-tabel-c black-table pagination-white"
              columns={gamingColumns}
              dataSource={rolePlay}
              pagination={{
                pageSize: 6,
                current: pageNo,
                total: totalPages * 6,
                onChange: (v) => {
                  setrolePlay();
                  setPageNo(v);
                },
                size: "small",
                showSizeChanger: false,
              }}
            />
          )}
        </div>
      </Modal>
    </>
  );
}
