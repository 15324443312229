import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Col,
  DatePicker,
  Empty,
  Input,
  InputNumber,
  Modal,
  Radio,
  Row,
  Slider,
  Spin,
  message,
} from "antd";

import { FaArrowLeft, FaPlus } from "react-icons/fa";
import logo from "../../../images/Telemit (5).png";
import { GoLock } from "react-icons/go";
import { BsUniversalAccess } from "react-icons/bs";

import { LoadingOutlined } from "@ant-design/icons";
import SearchContext from "../../../Context/SearchContext";
import Login from "../../login/Login";
import Signup from "../../signup/Signup";
import { MdPeopleAlt } from "react-icons/md";

export default function AddPerson({
  callPerson,
  setCallPerson,
  text,
  row,
  isAuto,
}) {
  const { demo, setDemo, user } = useContext(SearchContext);
  const [loading, setloading] = useState(false);
  const [gamesData, setGamesData] = useState();
  const [type, setType] = useState();
  const [isSignup, setIsSignup] = useState(false);
  useEffect(() => {
    if (demo.person === undefined && row.status === "watch") {
      if (user) {
        setloading(true);
        getrolePlay(user);
      } else {
        setGamesData({
          ...gamesData,

          name: "",

          email: "",
        });
      }
    }
  }, [demo.callSessionV]);
  const getrolePlay = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/feed/${row._id}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch");
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.isSuccess) {
          if (data.feedData?.live === "pending") {
            updateFeed({ live: "processing" }, row._id);
          }
          if (user || u) {
            let newUser = user ? user : u;
            savePeople({
              name: newUser.name,
              stream_id: row._id,
              email: newUser.email,
              status:
                newUser?.user_id && newUser?.user_id === row.user_id
                  ? "publisher"
                  : "active",
            });
          } else {
            savePeople({
              name: gamesData.name,
              stream_id: row._id,
              email: gamesData.email,
              status: "active",
            });
          }
        } else {
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
        // Handle error state here if needed
      });
  };
  const updateFeed = (obj, id) => {
    delete obj._id;

    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {});
  };
  const savePeople = (obj) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/people`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          const oldP = JSON.parse(window.localStorage.getItem("persons"));
          let all = oldP ? [...oldP, data.data] : [data.data];
          window.localStorage.setItem("persons", JSON.stringify(all));

          setDemo({
            ...demo,
            person: data.data,
            callPerson: demo.callPerson ? demo.callPerson + 1 : 1,
          });

          setGamesData();
        } else {
          message.error("something went wrong");
        }
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  const guestPerson = () => {
    return (
      <div>
        <div className="sign-in-right-content">
          <div className="logo-login-c">
            <img className="logo-img-bc" src={logo} alt="" />
          </div>
          <p className="sign-in-form-title">Create Guest Account</p>

          <form style={{ width: "327px" }} className="fonr-lgn-c">
            <div className="form-group mb-3">
              <label for="inputEmail" class="sign-in-form-field-label">
                Name *
              </label>
              <input
                type="text"
                placeholder="Name"
                value={gamesData.name}
                required=""
                autofocus=""
                className="sign-in-form-input"
                onChange={(e) => {
                  setGamesData({
                    ...gamesData,
                    name: e.target.value,
                  });
                }}
              />
            </div>
            <div className="form-group mb-3">
              <label for="inputEmail" class="sign-in-form-field-label">
                Email *
              </label>
              <input
                type="text"
                className="sign-in-form-input"
                placeholder=" Email"
                onChange={(e) => {
                  setGamesData({
                    ...gamesData,
                    email: e.target.value,
                  });
                }}
                value={gamesData.email}
              />
            </div>
            <div className="space-b-btns">
              <div />

              <div className="save-btn-pl">
                {loading ? (
                  <Spin indicator={antIcon} />
                ) : gamesData.name &&
                  gamesData.name.length &&
                  gamesData.email &&
                  gamesData.email.length ? (
                  <input
                    onClick={() => {
                      setloading(true);
                      getrolePlay(gamesData);
                    }}
                    type="submit"
                    data-wait="Please wait..."
                    class="sign-in-submit-button w-button"
                    value="Save"
                  />
                ) : // <div

                //   className="hover-card-b"
                //   style={{
                //     maxWidth: "300px",
                //   }}
                // >
                //   Save
                // </div>
                null}
              </div>
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="add-peron-main-c">
      {isAuto ? null : loading ? (
        <Spin indicator={antIcon} />
      ) : (
        <Button
          onClick={() => {
            if (user) {
              setloading(true);
              getrolePlay(user);
            } else {
              setGamesData({
                ...gamesData,

                name: "",

                email: "",
              });
            }
          }}

          // className="black-button"
        >
          {text}
        </Button>
      )}
      <Modal
        width={800}
        open={gamesData !== undefined}
        onCancel={() => {
          if (isAuto) {
          } else {
            setGamesData();
            setIsSignup(false);
            setType();
          }
        }}
        className={`no-f-no-h top-20-x  brand-modal ${
          isAuto ? " no-close-m" : ""
        }`}
      >
        <div className="add-percon-mains">
          {type ? (
            <FaArrowLeft
              onClick={() => setType()}
              size={18}
              className="back-icn hover"
            />
          ) : null}
          {gamesData ? (
            type ? (
              type === "guest" ? (
                <div className="modal-heigh-login">{guestPerson()}</div>
              ) : type === "login" ? (
                <div className="modal-heigh-login">
                  {isSignup ? (
                    <Signup setIsSignup={setIsSignup} />
                  ) : (
                    <Login
                      afterSave={(u) => {
                        setloading(true);
                        getrolePlay(u);
                        setGamesData();
                        setIsSignup(false);
                        setType();
                      }}
                      setIsSignup={setIsSignup}
                    />
                  )}
                </div>
              ) : null
            ) : (
              <div class="tele-main-card-c">
                <div className="logo-login-c">
                  <img className="logo-img-bc" src={logo} alt="" />
                </div>
                <h5> Access </h5>
                <div class="tele-cards">
                  <div>
                    <div
                      onClick={() => {
                        setType("login");
                        setIsSignup(false);
                      }}
                      class="tele-card tele-blue"
                    >
                      <p class="tele-tip">
                        <GoLock /> Login
                      </p>
                    </div>

                    <div
                      onClick={() => {
                        setIsSignup(true);
                        setType("login");
                      }}
                      class="tele-card tele-green"
                    >
                      <p class="tele-tip">
                        <BsUniversalAccess /> Sign Up
                      </p>
                    </div>

                    <div
                      onClick={() => setType("guest")}
                      class="tele-card tele-red"
                    >
                      <p class="tele-tip">
                        <MdPeopleAlt /> Guest
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            )
          ) : null}
        </div>
      </Modal>
    </div>
  );
}
