import React, { useContext, useEffect, useState } from "react";

import { Modal, Button, Input, Slider, message, Spin } from "antd";

import {
  FaArrowLeft,
  FaGamepad,
  FaLaptop,
  FaMobile,
  FaMobileAlt,
  FaPlus,
} from "react-icons/fa";
import logo from "../../../images/Telemit (5).png";
import { RiLiveFill } from "react-icons/ri";

import { LoadingOutlined } from "@ant-design/icons";
import SearchContext from "../../../Context/SearchContext";
import { TbLivePhoto, TbLivePhotoOff } from "react-icons/tb";
import Paragraph from "antd/es/typography/Paragraph";

export default function StartModal() {
  const { demo, setDemo, user } = useContext(SearchContext);
  const [loading, setloading] = useState(false);
  const [gamesData, setGamesData] = useState();
  const [type, setType] = useState();

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  const adjustTime = (t) => {
    setGamesData({
      ...gamesData,

      time_length: t,
    });
  };
  const formatDate = (inputDate) => {
    // Parse the input date string
    const date = new Date(inputDate);

    // Extract year, month, day, hour, and minute from the parsed date
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const hour = String(date.getHours()).padStart(2, "0");
    const minute = String(date.getMinutes()).padStart(2, "0");

    // Construct the desired date string in the format "YYYY-MM-DDTHH:MM:SS"
    const formattedDate = `${year}-${month}-${day}T${hour}:${minute}:00`;

    return formattedDate;
  };
  const savePlay = (row) => {
    const obj = {
      user_id: user?.user_id,

      status: "pending",

      media_type: gamesData.media_type,
      title: gamesData.title,
      device: gamesData.device,
      recording_type: gamesData.recording_type,
      instruction: gamesData.instruction,
      link: gamesData.link,
      version_id: gamesData.version_id,
      steam_key: gamesData.steam_key,
      platform: gamesData.platform,
      time_length: gamesData.time_length,
    };
    setGamesData();
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        setloading(false);
        if (response && response.isSuccess) {
          message.success("Added");
        } else if (response.msgError === "Insufficient credits") {
          // setShowPrice(true);
        } else {
          message.error("something went wrong");
        }
      });
  };
  const step1 = () => {
    return (
      <div class="tele-main-card-c">
        <div className="logo-login-c">
          <img className="logo-img-bc" src={logo} alt="" />
        </div>
        <h5> Device </h5>
        <div class="tele-cards">
          <div>
            <div
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  step: gamesData.step + 1,
                  device: "mobile",
                });
              }}
              class="tele-card tele-blue heigth-tele-card"
            >
              <p class="tele-tip">
                <FaMobileAlt />
                Mobile
              </p>
              <p className="tele-c-desc">
                Run your session on a mobile device such as iOS or Android
              </p>
            </div>

            <div
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  step: gamesData.step + 1,
                  device: "desktop",
                });
              }}
              class="tele-card tele-green heigth-tele-card"
            >
              <p class="tele-tip">
                <FaLaptop /> Desktop
              </p>
              <p className="tele-c-desc">
                Run your session on a operating system such as Windows, Mac or
                Linux
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const step2 = () => {
    return (
      <div class="tele-main-card-c">
        <div className="logo-login-c">
          <img className="logo-img-bc" src={logo} alt="" />
        </div>
        <h5> Stream </h5>
        <div class="tele-cards">
          <div>
            <div
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  step: gamesData.step + 1,
                  recording_type: "moderated",
                });
              }}
              class="tele-card tele-blue heigth-tele-card"
            >
              <p class="tele-tip">
                <TbLivePhoto className="circle" />
                Moderated
              </p>
              <p className="tele-c-desc">
                Watch your session's stream live and give instructions and
                feedback in real time
              </p>
            </div>

            <div
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  step: gamesData.step + 1,
                  recording_type: "unmoderated",
                });
              }}
              class="tele-card tele-green heigth-tele-card"
            >
              <p class="tele-tip">
                <TbLivePhotoOff /> Unmoderated
              </p>
              <p className="tele-c-desc">
                Restream the sessions gameplay after it is completed
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const feeds = () => {
    return (
      <div class="tele-main-card-c">
        <div className="logo-login-c">
          <img className="logo-img-bc" src={logo} alt="" />
        </div>
        <h5> Game </h5>

        {demo.data &&
          demo.data.map((d, di) => {
            return (
              <div key={di} class="tele-cards tele-more-space-c">
                <div>
                  <div class="tele-card tele-blue heigth-tele-card full-w-tele">
                    <img className="card-img-s1" src={d.thumbnail} alt="" />
                    <div className="tele-m-c-f1">
                      <div>
                        <p class="tele-tip">{d.title}</p>
                        <Paragraph
                          ellipsis={{
                            rows: 4,
                            // expandable: true,
                            symbol: "see more",
                          }}
                          style={{ width: "100%" }}
                          className="desc-p-crdjjkl"
                        >
                          {d.description}
                        </Paragraph>
                      </div>
                      <div className="flex-end">
                        <button
                          onClick={() => {
                            setGamesData({
                              ...gamesData,
                              step: gamesData.step + 1,
                              title: d.title,
                              link: d.link,
                              platform: d.platform,
                              version_id: d.version_id,
                              activeCard: d,
                            });
                          }}
                          className="my-lbutton"
                        >
                          Continue
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}

        <div style={{ alignItems: "center" }} class="tele-cards">
          <div>
            <div class="tele-card tele-blue heigth-tele-card">
              <p class="tele-tip">Add New Game</p>
            </div>

            <div
              onClick={() => {
                setGamesData({
                  ...gamesData,
                  step: gamesData.step + 1,
                });
              }}
              class="tele-card tele-green heigth-tele-card"
            >
              <p class="tele-tip">One Time Test</p>
            </div>
          </div>
        </div>
      </div>
    );
  };
  const addForm = () => {
    return (
      <div className=" form-pf">
        <div className="game-show-p1">
          <div>
            <div>
              <div>
                {gamesData?.activeCard ? (
                  <div
                    style={{ padding: "5px" }}
                    class="tele-cards tele-more-space-c gold-card-s"
                  >
                    <div>
                      <div class="tele-card tele-blue heigth-tele-card full-w-tele">
                        <img
                          className="card-img-s1"
                          src={gamesData?.activeCard?.thumbnail}
                          alt=""
                        />
                        <div className="tele-m-c-f1">
                          <div>
                            <p class="tele-tip">
                              {gamesData?.activeCard?.title}
                            </p>
                            <Paragraph
                              ellipsis={{
                                rows: 4,
                                // expandable: true,
                                symbol: "see more",
                              }}
                              style={{ width: "100%" }}
                              className="desc-p-crdjjkl"
                            >
                              {gamesData?.activeCard?.description}
                            </Paragraph>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
                {gamesData?.activeCard ? null : (
                  <label
                    style={{ margin: "15px 0px" }}
                    for="name"
                    class="label-pf"
                  >
                    <span class="title-pf">Name *</span>
                    <input
                      placeholder="Name"
                      onChange={(e) => {
                        setGamesData({
                          ...gamesData,

                          title: e.target.value,
                        });
                      }}
                      value={gamesData.title}
                      style={{ width: "100%" }}
                      class="input-field-pf"
                    />
                  </label>
                )}
                {gamesData?.activeCard ? null : (
                  <label
                    style={{ margin: "15px 0px" }}
                    for="link"
                    class="label-pf"
                  >
                    <span class="title-pf">Link *</span>
                    <input
                      placeholder="Link"
                      onChange={(e) => {
                        setGamesData({
                          ...gamesData,

                          link: e.target.value,
                        });
                      }}
                      value={gamesData.link}
                      style={{ width: "100%" }}
                      class="input-field-pf"
                    />
                  </label>
                )}

                <label
                  style={{ margin: "15px 0px" }}
                  for="version_id"
                  class="label-pf"
                >
                  <span class="title-pf">Version ID </span>
                  <input
                    placeholder="Version ID"
                    onChange={(e) => {
                      setGamesData({
                        ...gamesData,

                        version_id: e.target.value,
                      });
                    }}
                    value={gamesData.version_id}
                    style={{ width: "100%" }}
                    class="input-field-pf"
                  />
                </label>

                {gamesData?.activeCard ? null : (
                  <label
                    style={{ margin: "15px 0px" }}
                    for="Platform"
                    class="label-pf"
                  >
                    <span class="title-pf">Platform</span>
                    <select
                      class="input-field-pf"
                      onChange={(e) => {
                        setGamesData({
                          ...gamesData,

                          platform: e.target.value,
                          steam_key: undefined,
                        });
                      }}
                      value={gamesData.platform}
                    >
                      <option value={undefined}>Select Platform</option>

                      <>
                        <option value="chrome">Chrome</option>
                        <option value="epic_Store">Epic Store</option>
                        <option value="steam">Steam</option>
                        <option value="none">None</option>
                      </>
                    </select>
                  </label>
                )}
                {gamesData.platform === "steam" ? (
                  <label
                    style={{ margin: "15px 0px" }}
                    for="steam_key"
                    class="label-pf"
                  >
                    <span class="title-pf">Steam key </span>
                    <input
                      placeholder="Steam key"
                      onChange={(e) => {
                        setGamesData({
                          ...gamesData,

                          steam_key: e.target.value,
                        });
                      }}
                      value={gamesData.steam_key}
                      style={{ width: "100%" }}
                      class="input-field-pf"
                    />
                  </label>
                ) : null}

                <label
                  style={{ margin: "15px 0px" }}
                  for="name"
                  class="label-pf"
                >
                  <span class="title-pf">Instruction *</span>
                  <Input.TextArea
                    autoSize={{ minRows: 2, maxRows: 4 }}
                    placeholder="Instruction"
                    onChange={(e) => {
                      setGamesData({
                        ...gamesData,

                        instruction: e.target.value,
                      });
                    }}
                    value={gamesData.instruction}
                    style={{ width: "100%" }}
                    size="large"
                  />
                </label>

                <div
                  className="together-fields-c"
                  style={{ margin: "15px 0px" }}
                >
                  <label style={{ width: "100%" }} for="time" class="label-pf">
                    <span class="title-pf">Time *</span>
                    <div className="biling-m-ci1">
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                        }}
                        className="biling-m-c1 my-sli-play-f"
                      >
                        <Slider
                          min={1}
                          max={100}
                          onChange={adjustTime}
                          value={gamesData.time_length}
                          style={{ width: "100%" }}
                        />
                        <b>{gamesData.time_length} Hour</b>
                      </div>
                      {/* <div className="bottom-txt-sli">
                                <b>15</b>
                                <b>420 min</b>
                              </div> */}
                    </div>
                  </label>
                </div>
              </div>
            </div>
            {gamesData?.title &&
            gamesData?.title.length &&
            gamesData?.link &&
            gamesData?.link.length &&
            gamesData?.time_length &&
            gamesData?.platform &&
            gamesData?.platform.length &&
            gamesData?.instruction &&
            gamesData?.instruction.length ? (
              <div className="save-btn-pl flex-end">
                {loading ? (
                  <Spin />
                ) : (
                  <Button
                    onClick={() => {
                      // setloading(true);
                      // setGamesData({
                      //   ...gamesData,
                      //   showPlay: undefined,
                      // });
                      setloading(true);
                      savePlay(gamesData);
                    }}
                    size="large"
                    className="glow-on-hover"
                    style={{
                      maxWidth: "300px",
                    }}
                  >
                    Start
                  </Button>
                )}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="add-peron-main-c">
      <button
        onClick={() => {
          setGamesData({
            ...gamesData,
            step: 1,
            time_length: 1,
            name: "",

            email: "",
            activeCard: undefined,
          });
        }}
        style={{ padding: "10px 20px" }}
        className="my-lbutton gold-btn-c"
      >
        <FaGamepad /> Start
      </button>

      <Modal
        width={700}
        open={gamesData !== undefined}
        onCancel={() => {
          setGamesData();

          setType();
        }}
        className={`no-f-no-h top-20-x  brand-modal `}
      >
        {gamesData ? (
          <div style={{ minHeight: "50vh" }} className="add-percon-mains">
            {gamesData.step > 1 ? (
              <FaArrowLeft
                onClick={() => {
                  setGamesData({
                    ...gamesData,
                    step: gamesData.step - 1,
                  });
                }}
                size={18}
                className="back-icn hover"
              />
            ) : null}
            {gamesData.step === 1 ? feeds() : null}
            {gamesData.step === 2 ? step1() : null}
            {gamesData.step === 3 ? step2() : null}
            {gamesData.step === 4 ? addForm() : null}
          </div>
        ) : null}
      </Modal>
    </div>
  );
}
