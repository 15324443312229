import React, { useContext, useEffect, useState } from "react";
import { message, Spin } from "antd";

import { useNavigate } from "react-router-dom";

import bcrypt from "bcryptjs";
import SearchContext from "../../Context/SearchContext";
import { v4 as uuidv4 } from "uuid";
import logo from "../../images/Telemit (5).png";
import { LoadingOutlined } from "@ant-design/icons";

export default function Signup({ setIsSignup }) {
  const { user } = useContext(SearchContext);
  const [imageUrl, setImageUrl] = useState();
  const handleChange = (info) => {
    setImageUrl(info.file.originFileObj);
  };
  const navigate = useNavigate();
  const [form, setForm] = useState({
    email: "",
    name: "",
    password: "",
    country: "country",
    permission: "owner",
    language: "English",
    business_name: "",
  });
  const [emailErr, setEmailErr] = useState(false);
  const [nameErr, setNameErr] = useState(false);
  const [passErr, setPassErr] = useState(false);
  const [embNameErr, setBNameErr] = useState(false);

  const [loading, setLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u && u.name) {
      navigate("/");
    }
  }, []);
  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isButtonDisabled) {
      return; // Do nothing if the button is disabled
    }

    // Start loading spinner
    setIsButtonDisabled(true);

    console.log("Submit button clicked");

    const isEmail = testEmail();
    const isName = form.name.length > 0 ? false : true;
    const isBName = form.business_name.length > 0 ? false : true;
    const isPass = form.password.length > 7 ? false : true;

    setBNameErr(isBName);
    setNameErr(isName);
    setEmailErr(isEmail);
    setPassErr(isPass);

    if (!isEmail && !isName && !isBName && !isPass) {
      // Use setTimeout to simulate a 3-second delay
      setTimeout(async () => {
        try {
          await save(); // Perform form submission
        } catch (error) {
          // Handle errors if necessary
        } finally {
          setIsButtonDisabled(false); // Enable the button after 3 seconds
          setLoading(false); // Stop loading spinner
        }
      }, 3000); // 3000 milliseconds (3 seconds) delay
    } else {
      setIsButtonDisabled(false); // Enable the button in case of validation errors
      setLoading(false); // Stop loading spinner
    }
  };

  const testEmail = () => {
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(form.email)) {
      return false;
    } else {
      return true;
    }
  };

  const save = () => {
    const formData = new FormData();
    formData.append("business_name", form.business_name);
    formData.append("subscription", "Pro");
    formData.append("color_code", "black");
    formData.append("chat_count", 1);
    formData.append("root_email", form.email);
    formData.append("storage_usage_rate", 0.04);
    formData.append("compute_usage_rate", 10);
    formData.append("balance", 0);
    formData.append("earn", "pending");
    formData.append("onboard", "intro");

    const requestOptions = {
      method: "POST",
      headers: {
        // "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: formData,
    };
    fetch(`https://embed.axv.ai/api/organization`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isSuccess) {
          addUser(form, response.newOrganization);
        }
      });
  };

  const addUser = (user, org) => {
    user.password = bcrypt.hashSync(
      user.password,
      "$2a$10$CwTycUXWue0Thq9StjUM0u"
    );
    let id = uuidv4();
    user.profile_id = id;
    user.user_id = org._id;

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(user),
    };
    fetch(`https://embed.axv.ai/api/consumer/users`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isCreated) {
          setForm({
            email: "",
            name: "",
            password: "",
            country: "country",
            organization_id: "or id",
            user_id: "",
            permission: "nothing",
            language: "English",
            business_name: "",
          });
          if (setIsSignup) {
            setIsSignup(false);
          } else {
            navigate("/login");
          }
          sendEmailVerification(user.email);

          message.success({
            content: "Account Created. Please Log in",
            duration: 5, // Duration in seconds
            style: {
              fontSize: "10px", // Increase font size
            },
          });

          // setFormType("login");
        } else if (response.isExists) {
          message.warning("This email is already in use");
        }
      });
  };

  const sendEmailVerification = (email) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // Add any additional headers needed for the request
      },
      body: JSON.stringify({ email: email }),
    };

    fetch("https://embed.axv.ai/api/verification/send", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Email verification request failed");
        }
        return response.json();
      })
      .then((data) => {
        // Handle the response data if necessary
        console.log("Email verification request sent:", data);
      })
      .catch((error) => {
        // Handle errors if the request fails
        console.error("Error sending email verification request:", error);
      });
  };

  const changeField = (key, val, row) => {
    row[key] = val;
    setForm({ ...form, [key]: val });
  };
  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );
  return (
    <div className={`${setIsSignup ? "" : "blurry-background"}`}>
      <div className="login-page">
        <div className="col-sm-12">
          <div style={{ width: "auto" }} className="sign-in-right-content">
            {" "}
            <div className="">
              <div className="">
                <div className="logo-login-c">
                  <img className="logo-img-bc" src={logo} alt="" />
                </div>
                <p className="sign-in-form-title">Create Your Account</p>
                {/* <div className="sign-in-form-text-block">
                  Already have an account?{" "}
                  <span
                    onClick={() => setIsSignup(false)}
                    className=" sign-in-form-text-link hover"
                  >
                    <span>Login</span>
                  </span>
                </div> */}
              </div>{" "}
              <form className="fonr-lgn-c">
                <div className="two-input-together">
                  <div className="form-group mb-3">
                    <label for="inputName" class="sign-in-form-field-label">
                      Name *
                    </label>
                    <input
                      id="inputName"
                      type="text"
                      placeholder="Name"
                      required=""
                      value={form.name}
                      autofocus=""
                      className={`sign-in-form-input ${
                        nameErr ? " error-field" : ""
                      }`}
                      onChange={(e) =>
                        changeField("name", e.target.value, form)
                      }
                    />
                    {nameErr ? (
                      <p className="err-txt-signup">Name Required</p>
                    ) : null}
                  </div>
                  <div className="form-group mb-3 margin-l-2-">
                    <label for="inputBName" class="sign-in-form-field-label">
                      Business Name *
                    </label>
                    <input
                      id="inputBName"
                      type="text"
                      placeholder="Business Name"
                      required=""
                      autofocus=""
                      value={form.business_name}
                      className={`sign-in-form-input${
                        embNameErr ? " error-field" : ""
                      }`}
                      onChange={(e) =>
                        changeField("business_name", e.target.value, form)
                      }
                    />
                    {embNameErr ? (
                      <p className="err-txt-signup">Business Name Required</p>
                    ) : null}
                  </div>
                </div>
                <div className="form-group mb-3">
                  <label for="inputEmail" class="sign-in-form-field-label">
                    Your Email *
                  </label>
                  <input
                    id="inputEmail"
                    type="email"
                    value={form.email}
                    placeholder="Email address"
                    required=""
                    autofocus=""
                    className={`sign-in-form-input ${
                      emailErr ? " error-field" : ""
                    }`}
                    onChange={(e) =>
                      changeField("email", e.target.value.toLowerCase(), form)
                    }
                  />
                  {emailErr ? (
                    <p className="err-txt-signup">Need Valid Email</p>
                  ) : null}
                </div>
                <div className="form-group mb-3">
                  <label for="inputPassword" class="sign-in-form-field-label">
                    Your Password *
                  </label>
                  <input
                    id="inputPassword"
                    type="password"
                    value={form.password}
                    placeholder="Password"
                    onChange={(e) =>
                      changeField("password", e.target.value.trim(), form)
                    }
                    required=""
                    className={`sign-in-form-input ${
                      passErr ? " error-field" : ""
                    }`}
                  />
                  {passErr ? (
                    <p className="err-txt-signup">
                      Password Must be Atleast 8 Characters
                    </p>
                  ) : null}
                </div>

                <div style={{ marginTop: "20px" }} className="login-butotn-d">
                  <div className="flex-end">
                    {loading ? (
                      <Spin indicator={antIcon} size="large" />
                    ) : (
                      <input
                        onClick={(e) => {
                          setLoading(true);
                          handleSubmit(e);
                        }}
                        type="submit"
                        data-wait="Please wait..."
                        class={`sign-in-submit-button w-button ${
                          setIsSignup ? "" : " adjust-color--l"
                        }`}
                        value="Sign Up"
                        disabled={isButtonDisabled} // Set the disabled state of the button
                      />
                    )}
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
