import React, { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";

import { FaInfo, FaMinus } from "react-icons/fa";
import { MdClose } from "react-icons/md";

export default function FeedPopup({ active, setActive, setActiveRow , activeRow }) {
  const playerRef = useRef(null);
  const [adjusted , setAdjusted] = useState(false)
  const adjustPlay = (a) => {

    if (playerRef.current && !adjusted ) {
      setAdjusted(true)
      playerRef.current.seekTo(activeRow?.timestamp, "seconds");
    }
  };
  return active ? (
    <div class="unique-popup">
      <div className="close-iconscs">
        <MdClose
          onClick={() => {
            setActive();
            setActiveRow()
          }}
        />
      </div>

      <div className="video-container radius-video">
        <ReactPlayer
          onReady={() => {
            adjustPlay();
          }}
          ref={playerRef}
          playing
          url={active.file_url}
          className=""
          controls
        />
      </div>
    </div>
  ) : null;
}
