import React, { useContext, useEffect } from "react";
import SearchContext from "../Context/SearchContext";
import { Select } from "antd";
import { FaGamepad } from "react-icons/fa";
const { Option } = Select;

export default function ProjectFeed() {
  const { user, setUser, demo, setDemo } = useContext(SearchContext);
  useEffect(() => {
    if (demo.data === undefined) {
      getrolePlay();
    }
  }, []);

  const getrolePlay = () => {
    const u = JSON.parse(window.localStorage.getItem("user"));

    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed?page=1&limit=6&sort_by=${-1}&feed_type=game&user_id=${
        u?.user_id
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.feedList && data.feedList.length) {
          setDemo({ ...demo, data: data.feedList, activeP: data.feedList[0] });
          // setTotalPages(data.totalPages);
        }
      });
  };
  const adjustPro = (e) => {
    let data = demo.data.filter((f) => f._id === e);
    setDemo({ ...demo, activeP: data.length ? data[0] : undefined });
  };
  return demo.data ? (
    <Select
      value={demo.activeP?.title}
      onChange={(e) => {
        adjustPro(e);
      }}
      className="transparentinput"
      style={{ marginLeft: "20px" }}
    >
      {demo.data.map((d, di) => {
        return (
          <Option key={di} value={d._id}>
            {/* <FaGamepad size={30} style={{marginRight:"5px"}}/> */}
            {/* <img className="thumbnail-img-sel" src={demo.activeP?.thumbnail} alt=""/>  */}
            {d.title}
          </Option>
        );
      })}
    </Select>
  ) : null;
}
