import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";

import SimulationVideo from "./simulationVideo/SimulationVideo";

import { useContext, useEffect, useState } from "react";
import SearchContext from "../Context/SearchContext";
import LandingPage from "./landing/Landing";
import PricingPage from "./pricing/PricingPage";
import Onboard from "../components/onboard/Onboard";
import Dashboard from "./dashboard/Dashborad";
import Login from "./login/Login";
import Signup from "./signup/Signup";
import Accounts from "./account/Accounts";


function Pages() {
  const navigate = useNavigate();
  const { setUser, loginChange } = useContext(SearchContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      setUser(u);
    }
  }, [loginChange]);
  return (
    <>
    <Onboard />
      <Routes>
        {/* <Route path="/" element={<LandingOld />} /> */}
        <Route path="/" element={<LandingPage />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/pricing" element={<PricingPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/dashboard/account" element={<Accounts />} />
        
        <Route path="/:id" element={<SimulationVideo />} />
      </Routes>
    </>
  );
}

export default Pages;
