import React, { useContext, useEffect, useState } from "react";

import {
  Button,
  Col,
  Modal,
  Row,
  Select,
  Spin,
  Tag,
  message,
  Tabs,
  Input,
  Badge,
  Popconfirm,
} from "antd";
import "./dashboard.css";
import { Link, useNavigate } from "react-router-dom";
import { FaArrowDown, FaArrowRight, FaGamepad, FaPlay } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";

import FullLoader from "../../components/FullLoader";
import SearchContext from "../../Context/SearchContext";
import Header from "../../components/Header";
import image1 from "../../images/FznC6ff.jpg";
import { CgLivePhoto } from "react-icons/cg";
import { TbProgress } from "react-icons/tb";
import StartModal from "./components/StartModal";
import FeedPopup from "./components/FeedPopup";
import LiveTable from "./components/LiveTable";
import ProcessingTable from "./components/ProcessingTable";

export default function Dashboard() {
  const {
    user,
    gamesData,
    setGamesData,

    organization,
  } = useContext(SearchContext);

  const navigate = useNavigate();

  const [loading, setLoadeing] = useState(true);
  const [showVector, setShowVector] = useState(false);
  const [search, setsearch] = useState("");
  const [vData, setvData] = useState();
  const [active, setActive] = useState();
  const [activeRow, setActiveRow] = useState();
  const [callActive, setCallActive] = useState();

  const [count, setCount] = useState({});

  const [callApi, setCallApi] = useState(1);
  const { TabPane } = Tabs;
  useEffect(() => {
    // Store the original background image
    const originalBackgroundImage = document.body.style.backgroundImage;

    // Set the background image to none
    document.body.style.backgroundImage = `url(${image1})`;

    // Cleanup function to restore the original background image
    return () => {
      document.body.style.backgroundImage = originalBackgroundImage;
    };
  }, []);
  useEffect(() => {
    const u = JSON.parse(window.localStorage.getItem("user"));
    if (u) {
      getlist(u);
      getActiveData(u);
    } else {
      navigate("/");
    }
  }, [callApi]);
  useEffect(() => {
    if (callActive) {
      setActiveRow(callActive);
      setActive({});
      getActiveFeed(`/${callActive.stream_id}`);
    }
  }, [callActive]);

  const getActiveData = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed/count?user_id=${u?.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setCount(data);
        }
      });
  };
  const getActiveFeed = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setActive(data.feedData);
        }
      });
  };
  const getVectors = (u) => {
    const obj = {
      query: u,
      user_id: user?.user_id,
    };

    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/vector/search`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          setvData(data);
        }
      });
  };

  const getlist = (u) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/feed?page=${gamesData.pageNoMemo}&limit=12&status=watch&user_id=${u?.user_id}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          let all = [];
          if (gamesData.pageNoMemo && gamesData.pageNoMemo > 1) {
            all = gamesData.memoryData.concat(data.feedList);
          } else {
            all = [...data.feedList];
          }
          all = all?.map((obj) => ({ ...obj, key: obj._id }));

          setGamesData({
            ...gamesData,
            memoryData: all,

            totalPagesMemo: data.totalPages,
          });
        }

        setLoadeing(false);
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "white",
      }}
      spin
    />
  );

  const deleteTask = (row, key) => {
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(`https://embed.axv.ai/api/feed/${row._id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Deleted");

          const all = gamesData.oldData2.filter((p) => p._id !== row._id);

          setGamesData({
            ...gamesData,
            memoryData: all,

            totalPagesMemo: data.totalPages,
          });
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  const DynamicTimeDisplay = (d) => {
    let hours = Math.floor(d / 3600);
    hours = hours < 10 ? "0" + hours : hours;
    let minutes = Math.floor((d % 3600) / 60);
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let secondsRemaining = Math.floor(d % 60);
    let seconds =
      secondsRemaining < 10 ? "0" + secondsRemaining : secondsRemaining;

    return (
      <div className="flex-end-bvh">
        {hours}:{minutes}:{seconds}
      </div>
    );
  };
  const vectorData = () => {
    return (
      <div>
        {vData && vData.length === 0 ? (
          <div className="no-heigh-div-c editor-container">
            <div className="card21">
              <div className="card21-img"></div>
              <div className="card21-info">
                <p className="text21-body">No Sessions Completed</p>
                <p className="txt-card-21">
                  There are currently no sessions completed for your games.
                  Please create a session using the button below
                </p>
                <div>
                  <Button className="black-button btn-top-20"> Create</Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="assets-bx-h1">
          {vData === undefined ? (
            <div className="box-loading">
              <FullLoader />
            </div>
          ) : null}

          <Row>
            {vData &&
              vData.map((m, mi) => {
                return (
                  <Col
                    lg={{ span: 8 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                    key={mi}
                    //   className="display-goal-st-s"
                    style={{ padding: "15px" }}
                  >
                    <div className="public-video-card">
                      <img src={m.thumbnail ? m.thumbnail : image1} alt="" />

                      <div className="public-top-c">
                        <b>{m.title}</b>
                      </div>

                      <div className="public-top-c2">
                        <div className="btm-c-mem-crd">
                          <span>{m.mode ? m.mode : "Playtest"}</span>
                          {m.timestamp ? (
                            <span>{DynamicTimeDisplay(m.timestamp)}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="bottom-right-c">
                        <div
                          onClick={() => {
                            if (active) {
                              setActiveRow();
                              setActive();

                              setCallActive(m);
                            } else {
                              setActiveRow(m);
                              setActive({});
                              getActiveFeed(`/${m.stream_id}`);
                            }
                          }}
                          className="right-arrow-public"
                        >
                          <FaPlay />
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
    );
  };
  const feedData = () => {
    return (
      <div>
        {gamesData.memoryData && gamesData.memoryData.length === 0 ? (
          <div className="no-heigh-div-c editor-container">
            <div className="card21">
              <div className="card21-img"></div>
              <div className="card21-info">
                <p className="text21-body">No Sessions Completed</p>
                <p className="txt-card-21">
                  There are currently no sessions completed for your games.
                  Please create a session using the button below
                </p>
                <div>
                  <Button className="black-button btn-top-20"> Create</Button>
                </div>
              </div>
            </div>
          </div>
        ) : null}

        <div className="assets-bx-h1">
          {gamesData.memoryData === undefined ? (
            <div className="box-loading">
              <FullLoader />
            </div>
          ) : null}

          <Row>
            {gamesData.memoryData &&
              gamesData.memoryData.map((m, mi) => {
                return (
                  <Col
                    lg={{ span: 8 }}
                    md={{ span: 12 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                    key={mi}
                    //   className="display-goal-st-s"
                    style={{ padding: "15px" }}
                  >
                    <div className="public-video-card">
                      <img src={m.thumbnail ? m.thumbnail : image1} alt="" />

                      <div className="public-top-c">
                        <b>{m.title}</b>
                      </div>

                      <div className="public-top-c2">
                        <div className="btm-c-mem-crd">
                          <span>{m.mode ? m.mode : "Playtest"}</span>
                          {m.time_length ? (
                            <span>{DynamicTimeDisplay(m.time_length)}</span>
                          ) : null}
                        </div>
                      </div>
                      <div className="bottom-right-c">
                        <div
                          onClick={() => navigate(`/${m._id}`)}
                          className="right-arrow-public"
                        >
                          <FaPlay />
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>

          {gamesData.totalPagesMemo &&
          gamesData.totalPagesMemo > gamesData.pageNoMemo ? (
            <div className="load-more-btn">
              {loading ? (
                <Spin indicator={antIcon} />
              ) : (
                <Tag
                  onClick={() => {
                    setGamesData({
                      ...gamesData,
                      pageNoMemo: gamesData.pageNoMemo + 1,
                    });
                    setLoadeing(true);
                    setCallApi(callApi + 1);
                  }}
                  className={`editor-tag-tab  active-tg-wdt`}
                >
                  Load More <FaArrowDown />
                </Tag>
              )}
            </div>
          ) : null}
        </div>
      </div>
    );
  };
  return (
    <div className="sumilation-main-d">
      <Header />
      <div className="container">
        <div className="center-c-flx">
          <div className="input-main-search-ccs">
            <input
              value={search}
              onChange={(e) => {
                setsearch(e.target.value);
                if (e.target.value.length) {
                  getVectors(e.target.value);
                  setShowVector(true);
                } else {
                  setvData();
                  setShowVector(false);
                  setsearch(e.target.value);
                }
              }}
              required=""
              type="text"
              name="text"
              class="-stpinput"
            />
            {search && search.length ? null : (
              <label class="-stplabel">Search</label>
            )}
          </div>
          {/* <div className="InputContainer">
            <Input
              placeholder="Search"
              value={search}
              onChange={(e) => {
                setsearch(e.target.value);
                if (e.target.value.length) {
                  getVectors(e.target.value);
                  setShowVector(true);
                } else {
                  setvData();
                  setShowVector(false);
                  setsearch(e.target.value);
                }
              }}
              // size="large"
              className=" grey-input-center"
              allowClear
            />
          </div> */}
        </div>
        {/* <div class="search-container-ds">
                <input
                  value={search}
                  onChange={(e) => {
                    setsearch(e.target.value);
                    if (e.target.value.length) {
                      getVectors(e.target.value);
                      setShowVector(true);
                    } else {
                      setvData();
                      setShowVector(false);
                      setsearch(e.target.value);
                    }
                  }}
                  class="input-ds"
                  type="text"
                />
                <svg viewBox="0 0 24 24" class="search__icon">
                  <g>
                    <path d="M21.53 20.47l-3.66-3.66C19.195 15.24 20 13.214 20 11c0-4.97-4.03-9-9-9s-9 4.03-9 9 4.03 9 9 9c2.215 0 4.24-.804 5.808-2.13l3.66 3.66c.147.146.34.22.53.22s.385-.073.53-.22c.295-.293.295-.767.002-1.06zM3.5 11c0-4.135 3.365-7.5 7.5-7.5s7.5 3.365 7.5 7.5-3.365 7.5-7.5 7.5-7.5-3.365-7.5-7.5z"></path>
                  </g>
                </svg>
              </div> */}
        <div style={{ width: "100%" }} className="active-goals-tc21 ">
          <div
            style={{ padding: "20px", justifyContent: "space-between" }}
            className="btm-btns-tb-2"
          >
            <div className="flex">
              <LiveTable count={count} />
              <ProcessingTable count={count} />
            </div>

            <StartModal />
          </div>

          {/* <label for="inputEmail" class="sign-in-form-field-label">
                  Your Email 
                  *
                </label> */}

          {/* <input
            type="text"
            placeholder="Search"
            value={search}
            onChange={(e) => {
              setsearch(e.target.value);
              if (e.target.value.length) {
                getVectors(e.target.value);
                setShowVector(true);
              } else {
                setvData();
                setShowVector(false);
                setsearch(e.target.value);
              }
            }}
            size="large"
            className=" grey-input-center"
            allowClear
            /> */}

          {showVector ? vectorData() : feedData()}
        </div>
      </div>
      {active ? (
        <FeedPopup
          active={active}
          setActive={setActive}
          setActiveRow={setActiveRow}
          activeRow={activeRow}
        />
      ) : null}
    </div>
  );
}
