import React, { useContext, useEffect } from "react";
import MyAccount from "../../components/account/components/MyAccount";
import MyOrganization from "../../components/account/components/MyOrganization";
import MyTeam from "../../components/team/MyTeam";
import { Alert } from "antd";
import SearchContext from "../../Context/SearchContext";
import Header from "../../components/Header";
import AccountsHeader from "./components/AccountsHeader";
import Billing from "../billing/Billing";
import image1 from "../../images/blue-abstract-blue-background-gradient-abstract-3840x2160-8985.jpg";

export default function Accounts() {
  const { tasks, setTasks } = useContext(SearchContext);

  useEffect(() => {
    // Store the original background image
    const originalBackgroundImage = document.body.style.backgroundImage;

    // Set the background image to none
    document.body.style.backgroundImage = `url(${image1})`;

    // Cleanup function to restore the original background image
    return () => {
      document.body.style.backgroundImage = originalBackgroundImage;
    };
  }, []);
  return (
    <div className="sumilation-main-d">
      <Header />
      <div className="container">
        <AccountsHeader />
        {tasks.disAccount === undefined || tasks.disAccount === "account" ? (
          <MyAccount />
        ) : null}
        {tasks.disAccount === "Organization" ? <MyOrganization /> : null}
        {tasks.disAccount === "Team" ? <MyTeam /> : null}
        {tasks.disAccount === "Billing" ? <Billing /> : null}
      </div>
    </div>
  );
}
