import { Col, InputNumber, Row, Slider } from "antd";
import React, { useState } from "react";
import "./billing.css";
import Header from "../../components/Header";
export default function Billing() {
  const [inputValue, setInputValue] = useState(1);
  const onChange = (newValue) => {
    setInputValue(newValue);
  };

  return (
    <div className="container">
      <div className="form-pf">
        <div className="billing-page-main-cb ">
          <h3>Buy Your Credit</h3>
          <p>
            Buy Your Credit. This is perfect that runs the game.
          </p>
          <div className="biling-m-c ">
            <div className="biling-m-ci1">
              <div className="biling-m-c1">
                <Slider
                  min={1}
                  max={500}
                  onChange={onChange}
                  value={typeof inputValue === "number" ? inputValue : 0}
                />
              </div>
              <div className="bottom-txt-sli">
                <b>1</b>
                <b>500 min</b>
              </div>
            </div>
            {/* <div className="biling-m-c2 biling-m-ci1">
              <span>Total Credit</span>
              <InputNumber
                min={1}
                max={500}
                value={inputValue}
                onChange={onChange}
              />
            </div> */}
          </div>
          <div className="biling-m-c3">
            <div className="biling-m-ci1">
              {" "}
              Price <b className="price-b-m">$0.27</b>
            </div>
            <div style={{ margin: "0px 5px" }} className="biling-m-ci1">
              {" "}
              Total Minutes <b className="price-b-m">{inputValue}</b>
            </div>

            <div style={{ display: "flex" , alignItems:"center" }} className="biling-m-ci1">
              {" "}
              Total Price{" "}
              <b className="price-b-m">
                ${Number(inputValue * 0.27).toFixed(2)}
              </b>
            </div>
          </div>
          <div className="flex-end">
            <div
              style={{ margin: "20px 20px 0px 0px" }}
              className={`hover-card-b selected-crd `}
            >
              Buy
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
