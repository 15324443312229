import { Col, Row } from "antd";
import React, { useEffect } from "react";
import { TbArrowRight } from "react-icons/tb";
import "./pricing.css";
import { Link } from "react-router-dom";
import logo from "../../images/Telemit (5).png";

export default function PricingPage() {


  return (
    <div className="price-main-page-c">
      <div
        style={{ padding: "20px", position: "relative" }}
        className="landing-logo-no-u"
      >
        <Link to={"/"}>
          <img className="logo-img-bc" src={logo} alt="" />
        </Link>
        <div className="btn-menu-top-r-p">
          <a
            style={{ marginRight: "10px" }}
            href="https://calendly.com/paulw-zhrz/30min"
            target="blank"
          >
            <button className="my-lbutton">Request Access</button>
          </a>
          <a href={"https://telemit.com/login"}>
            <button className="my-lbutton">Login</button>
          </a>
        </div>
      </div>

      <div className="pricing-main-c1">
        <div className="pricing-main-c2">
          <h3>Pricing built for businesses of all sizes</h3>
          <Row>
          <Col
              lg={{ span: 6 }}
              md={{ span: 6 }}
              xl={{ span: 6 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ padding: "10px" }}
            ></Col>
            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              xl={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ padding: "10px" }}
            >
              <div className="left-p-2">
                <Row>
                  <Col span={14}>
                    <div className="left-p-21">
                      <div>
                        <h4> Standard</h4>
                        <p>
                          Telemit prices for the amount of hours of feedback and
                          marketing usage you request. Pay for the hours you
                          only use.
                        </p>
                      </div>
                      <div>
                        <a
                          href="https://calendly.com/paulw-zhrz/30min"
                          target="blank"
                          style={{ textDecoration: "none" }}
                        >
                          <div className={`my-lbutton black-my-button`}>
                            Request Access
                            <TbArrowRight />
                          </div>
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="left-p-221">
                      <h5>$0.09/min</h5>{" "}
                      <p>each minute Telemit is used.</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            {/* <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              xl={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ padding: "10px" }}
            >
              <div className="left-p2-2">
                <Row>
                  <Col span={14}>
                    <div className="left-p-21">
                      <div>
                        <h4> Custom</h4>
                        <p>
                          Design a custom package that is tailored to your needs
                          and timeline.
                        </p>
                      </div>
                      <div>
                        <div className={`my-lbutton `}>
                          Contact Sales
                          <TbArrowRight />
                        </div>
                      </div>
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="left-p2-221">
                      <h5>Fine tuned models</h5>
                      <h5>Volume discounts</h5>
                      <h5>Unlimited storage</h5>
                      <h5>Enterprise support</h5>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col> */}
          </Row>
          {/* <div>
            <h4 className="snd-card-stitle-c">Individuals & Small Teams</h4>
          </div>
          <Row>
            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              xl={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ padding: "10px" }}
            >
              <div className="left-p-2">
                <Row>
                  <Col span={14}>
                    <div className="left-p-21">
                      <div>
                        <h4> Community</h4>
                        <p>
                          Watch live or restream any telemission from publishers
                          from your mobile or desktop.
                        </p>
                      </div>
                      <div style={{ display: "flex" }}>
                        <a
                          style={{
                            marginRight: "10px",
                            textDecoration: "none",
                          }}
                          href="https://calendly.com/paulw-zhrz/30min"
                          target="blank"
                        >
                          <div className={`my-lbutton black-my-button`}>
                            Get Started
                            <TbArrowRight />
                          </div>
                        </a>
                      </div>
                    </div>
                  </Col>
                  <Col span={10}>
                    <div className="left-p-221">
                      <h5>Free</h5> <p>each minute telemission is running</p>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
            <Col
              lg={{ span: 12 }}
              md={{ span: 12 }}
              xl={{ span: 12 }}
              sm={{ span: 24 }}
              xs={{ span: 24 }}
              style={{ padding: "10px" }}
            >
              <div className="left-p2-2">
                <Row>
                  <Col span={24}>
                    <div className="left-p-21">
                      <div>
                        <h4> Indie Developer or Startup?</h4>
                        <p>
                          Are you a indie developer or startup looking for a
                          cost effective solution? Build a solution tailored to
                          your needs
                        </p>
                      </div>
                      <div>
                        <a
                          href="https://calendly.com/paulw-zhrz/30min"
                          target="blank"
                          style={{ textDecoration: "none" }}
                        >
                          <div className={`my-lbutton `}>
                            Request Access
                            <TbArrowRight />
                          </div>
                        </a>
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row> */}
        </div>
      </div>
    </div>
  );
}
