import {
  Button,
  Col,
  Input,
  Segmented,
  Spin,
  Tag,
  Tooltip,
  message,
} from "antd";
import React, { useState } from "react";
import { MdClose, MdError } from "react-icons/md";
import GreenTick from "../../../components/GreenTick";
import { RiSendPlane2Fill } from "react-icons/ri";
import SessionTab from "./SessionTab";
import logo from "../../../images/Copy of ModelUI (256 x 256 px) (9).png";
import ScoreTab from "./ScoreTab";

export default function RightSide({
  showRight,
  isMobile,
  setDChat,
  setShowRight,
  setTab,
  tab,
  vectorView,
  vLoading,
  DynamicTimeDisplay,
  instructions,
  antIcon,
  emptyAlert,
  showInput,
  row,
  setcallVector,
  callVector,
  setTopic,
  topic,
  setCallInstruction,
  callInstruction,
  hideHightlight,
  showSession,
}) {
  const [loading, setLoading] = useState();
  const [value, setValue] = useState("");

  const createTask = () => {
    const obj = {
      instruction: value,
      stream_id: row._id,
      status: "pending",
    };
    setValue("");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/instruction`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setCallInstruction(callInstruction + 1);
        } else {
          message.error("Something Went Wrong");
        }
      });
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !loading) {
      e.preventDefault(); // Prevent the default Enter key behavior (e.g., adding a new line)
      createTask(); // Call the function to submit the form
      setLoading(true); // Set loading state if needed
    }
  };
  return showRight ? (
    <Col
      style={{ padding: isMobile ? "0px" : "10px" }}
      lg={{ span: 8 }}
      md={{ span: 8 }}
      xl={{ span: 8 }}
      sm={{ span: 24 }}
      xs={{ span: 24 }}
    >
      <div
        style={{ height: isMobile ? "50vh" : "85vh" }}
        className="chat-video-right vec-r"
      >
        <div className="tab-r-vc">
          {/* <Segmented
            options={["Daily", "Weekly", "Monthly", "Quarterly", "Yearly"]}
            onChange={(value) => {
              console.log(value); // string
            }}
          /> */}
          {hideHightlight ? null : (
            <Button
              onClick={() => setTab("vector")}
              type={tab === "vector" ? "primary" : "link"}
              className="tabs-c-link"
            >
              Observe
            </Button>
          )}
          <Button
            onClick={() => setTab("instructions")}
            type={tab === "instructions" ? "primary" : "link"}
            className="tabs-c-link"
          >
            Instructions
          </Button>
          {showSession ? (
            <Button
              onClick={() => setTab("sessions")}
              type={tab === "sessions" ? "primary" : "link"}
              className="tabs-c-link"
            >
              Sessions
            </Button>
          ) : null}
          {row.mode === "playtest" ? (
            <Button
              onClick={() => setTab("score")}
              type={tab === "score" ? "primary" : "link"}
              className="tabs-c-link"
            >
              Score
            </Button>
          ) : null}
        </div>
        {tab === "vector" && hideHightlight === undefined ? (
          <>
            {" "}
            {row.topics && row.topics.length ? (
              <div className="tags-area-topic">
                <Tag
                  onClick={() => {
                    setTopic("");
                    setcallVector(callVector + 1);
                  }}
                  className={`normal-topic-t ${
                    topic === "" ? " active-topi-t" : ""
                  }`}
                >
                  All
                </Tag>
                {row.topics.map((t, ti) => {
                  return (
                    <Tag
                      onClick={() => {
                        setTopic(t);
                        setcallVector(callVector + 1);
                      }}
                      key={ti}
                      className={`normal-topic-t ${
                        topic === t ? " active-topi-t" : ""
                      }`}
                    >
                      {t}
                    </Tag>
                  );
                })}
              </div>
            ) : null}
            {vLoading ? (
              <div
                style={{ width: "100%", height: "100%" }}
                className="loader-video-img"
              >
                <img src={logo} alt="" />
              </div>
            ) : (
              vectorView()
            )}
          </>
        ) : null}
        {tab === "instructions" ? (
          <div className="ins-height-sc">
            {instructions && instructions.length === 0 ? emptyAlert() : null}
            {instructions &&
              instructions.map((ins, i) => {
                return (
                  <div key={i} className="ins-watch-c">
                    <p>{ins.instruction}</p>
                    <div className="flex-end">
                      <b>
                        {ins.status === "complete" ? (
                          <GreenTick width={"25px"} height={"25px"} />
                        ) : null}
                        {ins.status === "pending" ? (
                          <Spin indicator={antIcon} />
                        ) : null}
                        {ins.status === "error" ? (
                          <Tooltip title="Something Went Wrong">
                            <MdError className="error-s-svg" />
                          </Tooltip>
                        ) : null}
                      </b>
                    </div>
                  </div>
                );
              })}
            {showInput ? (
              <div className="chat-bx-input-c">
                <Input.TextArea
                  placeholder="Type..."
                  autoSize={{ minRows: 1, maxRows: 3 }}
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  class="input-field-pf"
                  onKeyDown={handleKeyDown} // Listen for Enter key press
                />

                {loading ? (
                  <Spin indicator={antIcon} />
                ) : (
                  <div
                    className={`icon-left-ed   active-i-l-ed`}
                    style={{ width: "34px" }}
                    onClick={() => {
                      if (!loading) {
                        createTask();
                        setLoading(true);
                      }
                    }}
                  >
                    <RiSendPlane2Fill />
                  </div>
                )}
              </div>
            ) : null}
          </div>
        ) : null}
        {tab === "sessions" ? <SessionTab row={row} /> : null}
        {tab === "score" ? <ScoreTab row={row} /> : null}
      </div>
    </Col>
  ) : null;
}
