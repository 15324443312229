import { Button, Col, Popconfirm, Popover, Row, Spin } from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { FaArrowRight, FaInfoCircle, FaMinus } from "react-icons/fa";
import ReactPlayer from "react-player";

import { LoadingOutlined } from "@ant-design/icons";
import AddPerson from "./components/AddPerson";
import DemoRight from "./components/DemoRight";
import SearchContext from "../../Context/SearchContext";
import Header from "../../components/Header";
import FullLoader from "../../components/FullLoader";
import { useMediaQuery } from "react-responsive";

export default function DemoStatusLive({ row }) {
  const isMobile = useMediaQuery({ maxWidth: 767 });
  const { demo, setDemo } = useContext(SearchContext);

  const [dChat, setDChat] = useState(false);
  const [vectors, setVectors] = useState([]);
  const [activeId, setactiveId] = useState();
  const [instructions, setInstructions] = useState();
  const [showRight, setShowRight] = useState(false);
  const [showStartT, setShowStartT] = useState(true);
  const [showStartT2, setShowStartT2] = useState(false);
  const [tab, setTab] = useState("instructions");
  const [loading, setLoading] = useState();
  const [people, setPeople] = useState();
  const [person, setPerson] = useState("");
  const [callInstruction, setCallInstruction] = useState(1);
  const [callPerson, setCallPerson] = useState(1);
  const playerRef = useRef(null);

  useEffect(() => {
    getPersons();
  }, [callPerson, demo.callPerson]);

  const getPersons = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/people?stream_id=${row._id}&page=1&limit=100&status=active&sort_by=${-1}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setTimeout(() => {
            setCallPerson(callPerson + 1);
          }, 15000);
          setPeople(data.peopleList);
          checkPersonUpdated(data.peopleList);
          if (data.peopleList && data.peopleList.length) {
            setDChat(true);
            setTimeout(() => {
              setShowRight(true);
            }, 500);
          }
        }
      });
  };

  useEffect(() => {
    getInstructions(window.location.pathname.slice(11));
  }, [callInstruction]);

  const getInstructions = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/instruction?stream_id=${
        row._id
      }&page=1&limit=100&sort_by=${-1}`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          setTimeout(() => {
            setCallInstruction(callInstruction + 1);
          }, 15000);
          setInstructions(data.instructionList);
        }
      });
  };

  const checkPersonUpdated = (data) => {
    const p = JSON.parse(window.localStorage.getItem("person"));
    if (p && p._id) {
      let myPerson = data.filter((f) => f._id === p._id);
      myPerson = myPerson.length ? myPerson[0] : undefined;
      // console.log(p ,  myPerson , data)
      if (
        myPerson &&
        myPerson._id === p._id &&
        myPerson.seat_status !== p.seat_status
      ) {
        setDemo({
          ...demo,
          person: myPerson,
        });
        window.localStorage.setItem("person", JSON.stringify(myPerson));
      }
    }
  };
  const DynamicTimeDisplay = (d) => {
    let hours = Math.floor(d / 3600);
    hours = hours < 10 ? "0" + hours : hours;
    let minutes = Math.floor((d % 3600) / 60);
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let secondsRemaining = Math.floor(d % 60);
    let seconds =
      secondsRemaining < 10 ? "0" + secondsRemaining : secondsRemaining;

    return (
      <div className="flex-end-bvh">
        <p>
          {hours}:{minutes}:{seconds}
        </p>
        <Button
          onClick={() => adjustVideoTime(d)}
          icon={<FaArrowRight />}
          type="primary"
          size="small"
        ></Button>
      </div>
    );
  };

  const adjustVideoTime = (seconds) => {
    // Ensure React Player reference is available
    if (playerRef.current) {
      // Seek to the specified time in seconds
      playerRef.current.seekTo(seconds, "seconds");
    }
  };

  const emptyAlert = () => {
    return (
      <div class="notifications-container">
        <div class="dad-joke">
          <div class="flex">
            <div class="flex-shrink-0">
              <FaInfoCircle class="dad-joke-svg" />
            </div>
            <div class="dad-joke-prompt-wrap">
              <p class="dad-joke-prompt-heading">Dad Jokes Alert!</p>
              <div class="dad-joke-prompt">
                <p>
                  Why don’t scientists trust atoms? Because they make up
                  everything.
                </p>
              </div>
              <div class="dad-joke-button-container flex-end">
                <button class="dad-joke-button-main" type="button">
                  undefined
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
        color: "#bedbff",
        marginLeft: "5px",
      }}
      spin
    />
  );
  const updatePlay = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => response.json())
      .then((response) => {
        if (response && response.isPatched) {
          window.location.reload();
        }
      });
  };
  return (
    <div className="sumilation-main-d">
      <Header />

      <div className="container-my-20">
        <Row>
          <Col
            style={{ padding: isMobile ? "0px 0px 3px 0px" : "10px" }}
            lg={{ span: 16 }}
            md={{ span: 16 }}
            xl={{ span: 16 }}
            sm={{ span: 24 }}
            xs={{ span: 24 }}
          >
            <div style={{ flexDirection: "column" }} className=" video-watch-c">
              {dChat ? null : (
                <div className="chat-open-icn">
                  <AddPerson
                    setPerson={setPerson}
                    row={row}
                    setCallPerson={setCallPerson}
                    callPerson={callPerson}
                  />
                  {/* <BiSolidBrain
                      onClick={() => {
                        setDChat(true);
                        setTimeout(() => {
                          setShowRight(true);
                        }, 500);
                      }}
                    /> */}
                </div>
              )}
              <div
                style={{ height: "auto", minHeight: "450px" }}
                className="video-container video-container-round"
              >
                <div className="flex-center">
                  <h5
                    style={{ marginBottom: "10px" }}
                    className="gradient-text-title"
                  >
                    {row.title}
                  </h5>
                </div>
                {row.live === "ready" ? (
                  <iframe
                    title="External Website"
                    src={row.stream_url}
                    width="100%"
                    height="400px"
                    frameBorder="0"
                    allowFullScreen
                  />
                ) : (
                  // <ReactPlayer
                  //   ref={playerRef}
                  //   loop
                  //   url={row.file_url}
                  //   playing
                  //   play
                  //   muted
                  //   controls
                  //   onProgress={(a, b) => {
                  //     if (
                  //       vectors.filter(
                  //         (f) => f.timestamp === Math.floor(a.playedSeconds)
                  //       ).length
                  //     ) {
                  //       const targetDiv = document.getElementById(
                  //         `id${Math.floor(a.playedSeconds)}`
                  //       );
                  //       var container =
                  //         document.getElementById("container-vector");

                  //       if (targetDiv && container) {
                  //         // Calculate the y-coordinate of the target div relative to the container
                  //         var yOffset =
                  //           targetDiv.offsetTop - container.offsetTop;
                  //         setactiveId(`id${Math.floor(a.playedSeconds)}`);
                  //         // Scroll to the target div smoothly
                  //         container.scrollTo({
                  //           top: yOffset,
                  //           behavior: "smooth",
                  //         });
                  //       } else {
                  //         console.error(
                  //           "Element with the specified id not found."
                  //         );
                  //       }
                  //     }
                  //   }}
                  // />
                  <div className="loading-heigh-div">
                    <FullLoader />
                    <h5>Starting Game...</h5>
                  </div>
                )}
              </div>
              {isMobile ? null : (
                <div className="people-d-bvc">
                  <h5
                    style={{ marginBottom: "10px", textAlign: "center" }}
                    className="gradient-text-title"
                  >
                    Audience
                  </h5>
                  <div className="people-d-bvc23">
                    {people &&
                      people.map((p, pi) => {
                        return (
                          <Popover
                            content={
                              <div>
                                {p.status === "publisher" ? (
                                  <div> this is publisher</div>
                                ) : p.seat_status === "instructor" ? (
                                  <div> this is instructor</div>
                                ) : null}
                              </div>
                            }
                            key={pi}
                            title={p.name}
                          >
                            <div
                              className={`people-d-bv ${
                                p.status === "publisher"
                                  ? " active-p-bv"
                                  : p.seat_status === "instructor"
                                  ? " active-p-pub"
                                  : ""
                              }`}
                            >
                              {pi + 1}
                            </div>
                          </Popover>
                        );
                      })}
                  </div>
                </div>
              )}
              {demo.person?.status === "publisher" ? (
                <div style={{ paddingTop: "20px" }} className="flex-end">
                  <Popconfirm
                    title="this is title"
                    onConfirm={() => {
                      setLoading(true);
                      updatePlay({ status: "watch" }, row._id);
                    }}
                    // onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                    placement="right"
                  >
                    {loading ? (
                      <Spin />
                    ) : (
                      <Button type="primary" className="red-button">
                        End Stream{" "}
                      </Button>
                    )}
                  </Popconfirm>
                </div>
              ) : null}
            </div>
          </Col>
          <DemoRight
            showRight={showRight}
            dChat={dChat}
            setDChat={setDChat}
            setShowRight={setShowRight}
            setTab={setTab}
            tab={tab}
            vectors={vectors}
            activeId={activeId}
            DynamicTimeDisplay={DynamicTimeDisplay}
            instructions={instructions}
            setInstructions={setInstructions}
            antIcon={antIcon}
            emptyAlert={emptyAlert}
            row={row}
            setCallInstruction={setCallInstruction}
            callInstruction={callInstruction}
            showInput={true}
            setPerson={setPerson}
            people={people}
            isMobile={isMobile}
          />
        </Row>
      </div>
    </div>
  );
}
