import {
  Button,
  Col,
  Input,
  Popconfirm,
  Spin,
  Tag,
  Tooltip,
  message,
  notification,
} from "antd";
import React, { useContext, useState } from "react";
import { MdClose, MdError } from "react-icons/md";
import GreenTick from "../../../components/GreenTick";
import { RiSendPlane2Fill } from "react-icons/ri";
import SearchContext from "../../../Context/SearchContext";
import Paragraph from "antd/es/typography/Paragraph";
import moment from "moment";

export default function DemoRight({
  showRight,
  isMobile,
  setDChat,
  setShowRight,
  setTab,
  tab,
  people,
  instructions,
  antIcon,
  emptyAlert,
  showInput,
  row,
  setCallInstruction,
  callInstruction,
  setInstructions,
}) {
  const [loading, setLoading] = useState();
  const [value, setValue] = useState("");
  const { demo, setDemo } = useContext(SearchContext);
  const [api, contextHolder] = notification.useNotification();
  const createTask = () => {
    const obj = {
      instruction: value,
      stream_id: row._id,
      status: "pending",
      people_name: demo.person?.name,
      people_id: demo.person?._id,
    };
    setValue("");
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        // "x-api-key": process.env.REACT_APP_API_KEY,
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/instruction`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setLoading(false);
        if (data && data.isSuccess) {
          setCallInstruction(callInstruction + 1);
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !loading) {
      e.preventDefault(); // Prevent the default Enter key behavior (e.g., adding a new line)
      createTask(); // Call the function to submit the form
      setLoading(true); // Set loading state if needed
    }
  };
  const enableChat = () => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };
    fetch(
      `https://embed.axv.ai/api/people?stream_id=${row._id}&seat_status=instructor`,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          if (data.peopleList && data.peopleList.length === 0) {
            updatePerson({ seat_status: "instructor" }, demo.person?._id);
          } else {
            message.info("already taken");
          }
        } else {
          message.error("something went wrong ");
        }
      });
  };
  const updatePerson = (obj, id, storage) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/people/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          // window.localStorage.removeItem("person")

          if (storage !== "no") {
            window.localStorage.setItem(
              "person",
              JSON.stringify(data.peopleData)
            );
          }

          setDemo({
            ...demo,
            person: storage !== "no" ? data.peopleData : demo.person,
            callPerson: demo.callPerson ? demo.callPerson + 1 : 1,
          });
        }
      });
  };
  const updateInstruction = (obj, id) => {
    const requestOptions = {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(obj),
    };
    fetch(`https://embed.axv.ai/api/instruction/${id}`, requestOptions)
      .then((response) => response.json())
      .then((data) => {
        if (data && data.isSuccess) {
          message.success("Updated");
          const all = instructions.map((ins) =>
            ins._id === id ? data.instructionData : ins
          );
          setInstructions(all);
        } else {
          message.error("Something Went Wrong");
        }
      });
  };
  const audienceDiv = () => {
    return (
      <div className="ins-height-sc">
        {people &&
          people.map((p, pi) => {
            return (
              <div
                style={{ position: "relative" }}
                key={pi}
                className="ins-watch-c"
              >
                {demo.person?.status === "publisher" ? (
                  <Popconfirm
                    title="Cancel"
                    onConfirm={() =>
                      updatePerson({ status: "removed" }, p._id, "no")
                    }
                    // onCancel={cancel}
                    okText="Yes"
                    cancelText="No"
                    placement="right"
                  >
                    <b className="show-hover remover-person-rg">
                      <MdClose
                        style={{
                          position: "relative",
                          zIndex: 120,
                        }}
                        className="c"
                      />
                    </b>
                  </Popconfirm>
                ) : null}
                <h6>{p.name}</h6>
                <div className="space-between">
                  {demo.person?.status === "publisher" &&
                  p.seat_status === "instructor" ? (
                    <Popconfirm
                      title="Cancel"
                      onConfirm={() =>
                        updatePerson({ seat_status: "watcher" }, p._id, "no")
                      }
                      // onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                      placement="right"
                    >
                      <Button className="red-button " size="small">
                        {" "}
                        Downgrade
                      </Button>
                    </Popconfirm>
                  ) : (
                    <span />
                  )}

                  <span style={{ fontSize: "11px" }}>
                    {moment(p.createdAt).fromNow()}
                  </span>
                </div>
              </div>
            );
          })}
      </div>
    );
  };
  const instructionsDiv = () => {
    return (
      <div className="ins-height-sc">
        {instructions && instructions.length === 0 ? emptyAlert() : null}
        {instructions &&
          instructions.map((ins, i) => {
            return (
              <div
                key={i}
                className={`ins-watch-c ${
                  ins.status === "pending" ? " active-p-pen" : ""
                }`}
              >
                <Paragraph
                  ellipsis={{
                    rows: 3,
                    // expandable: true,
                    symbol: "see more",
                    tooltip: ins.instruction,
                  }}
                >
                  {ins.instruction}
                </Paragraph>
                <div className="space-between">
                  <i className="people-name-bcs">{ins.people_name}</i>
                  <b>
                    {ins.status === "complete" ? (
                      <GreenTick width={"25px"} height={"25px"} />
                    ) : null}
                    {ins.status === "cancel" ? (
                      <span style={{ color: "red" }}>Canceled</span>
                    ) : null}
                    {ins.status === "pending" ? (
                      <span>
                        {demo.person?.seat_status === "instructor" ? (
                          <Popconfirm
                            title="Cancel"
                            onConfirm={() =>
                              updateInstruction({ status: "cancel" }, ins._id)
                            }
                            // onCancel={cancel}
                            okText="Yes"
                            cancelText="No"
                            placement="right"
                          >
                            <Button
                              className="red-button show-hover"
                              size="small"
                            >
                              {" "}
                              cancel
                            </Button>
                          </Popconfirm>
                        ) : null}
                        <Spin
                          className={
                            demo.person?.seat_status === "instructor"
                              ? "hide-hover"
                              : ""
                          }
                          indicator={antIcon}
                        />
                      </span>
                    ) : null}
                    {ins.status === "error" ? (
                      <Tooltip title="Something Went Wrong">
                        <MdError className="error-s-svg" />
                      </Tooltip>
                    ) : null}
                  </b>
                </div>
              </div>
            );
          })}

        {instructions &&
        instructions.filter((f) => f.status === "pending").length ? (
          <div style={{ justifyContent: "center" }} className="chat-bx-input-c">
            {" "}
            <h6 style={{ marginTop: "10px" }}>Agent Completing Instruction</h6>
          </div>
        ) : demo.person?.seat_status === "instructor" ? (
          <div className="chat-bx-input-c">
            <Input.TextArea
              placeholder="Type..."
              autoSize={{ minRows: 1, maxRows: 3 }}
              value={value}
              onChange={(e) => setValue(e.target.value)}
              class="input-field-pf"
              onKeyDown={handleKeyDown} // Listen for Enter key press
            />

            {loading ? (
              <Spin indicator={antIcon} />
            ) : (
              <div
                className={`icon-left-ed   active-i-l-ed`}
                style={{ width: "34px" }}
                onClick={() => {
                  if (!loading) {
                    createTask();
                    setLoading(true);
                  }
                }}
              >
                <RiSendPlane2Fill />
              </div>
            )}
          </div>
        ) : (
          <div style={{ justifyContent: "center" }} className="chat-bx-input-c">
            {people &&
            people.filter((f) => f.seat_status === "instructor").length ===
              0 ? (
              <Button
                style={{ marginTop: "10px" }}
                className="gold-button"
                onClick={enableChat}
              >
                {" "}
                Become Instructor
              </Button>
            ) : (
              <Tag style={{ marginTop: "10px" , fontWeight:"bold" , color:"black" }} color="rgb(255 214 79)">
                {people.filter((f) => f.seat_status === "instructor")[0]?.name}{" "}
                is instructor.....
              </Tag>
            )}
          </div>
        )}
      </div>
    );
  };
  return showRight ? (
    <Col
      style={{ padding: isMobile ? "0px" : "10px" }}
      lg={{ span: 8 }}
      md={{ span: 8 }}
      xl={{ span: 8 }}
      sm={{ span: 24 }}
      xs={{ span: 24 }}
    >
      <div
        style={{ height: isMobile ? "50vh" : "85vh" }}
        className="chat-video-right vec-r"
      >
        <div className="tab-r-vc">
          <Button
            onClick={() => setTab("instructions")}
            type={tab === "instructions" ? "primary" : "link"}
            className="tabs-c-link"
          >
            Instructions
          </Button>
          <Button
            onClick={() => setTab("audience")}
            type={tab === "audience" ? "primary" : "link"}
            className="tabs-c-link"
          >
            Audience
          </Button>
          {demo.person?.status === "publisher" ? (
            <Button
              onClick={() => setTab("publishers")}
              type={tab === "publishers" ? "primary" : "link"}
              className="tabs-c-link"
            >
              Publishers
            </Button>
          ) : null}
        </div>
        {tab === "instructions" ? instructionsDiv() : null}
        {tab === "audience" ? audienceDiv() : null}
        {demo.person?.seat_status === "instructor" && tab === "instructions" ? (
          <div className="leave-btn-css">
            <Popconfirm
              title="this is title"
              onConfirm={() =>
                updatePerson({ seat_status: "watcher" }, demo.person?._id)
              }
              // onCancel={cancel}
              okText="Yes"
              cancelText="No"
              placement="top"
            >
              <Button type="primary" size="small" className="red-button">
                Leave As Instructor
              </Button>
            </Popconfirm>
          </div>
        ) : null}
      </div>
    </Col>
  ) : null;
}
