import { Col, Row, Select } from "antd";

import React, { useContext, useEffect, useRef, useState } from "react";

import { FaArrowDown, FaMinus, FaPlay } from "react-icons/fa";
import { LoadingOutlined } from "@ant-design/icons";
import SearchContext from "../../Context/SearchContext";
import { BsChatDots } from "react-icons/bs";
import SimulationChat from "./components/SimulationChat";
import logo from "../../images/Copy of ModelUI (256 x 256 px) (9).png";
import "./videolive.css";
import EmptyTyping from "../../components/EmptyTyping";
import { useLocation, useNavigate } from "react-router-dom";
import WatchStatus from "./WatchStatus";
import LiveStatus from "./LiveStatus";
import DemoStatusLive from "./DemoStatusLive";
import PreeLoad from "./preeLoad/PreeLoad";
import AddPerson from "./components/AddPerson";
import image1 from '../../images/blue-abstract-blue-background-gradient-abstract-3840x2160-8985.jpg'

const { Option } = Select;
export default function SimulationVideo({ row }) {
  const { demo, setDemo, user } = useContext(SearchContext);
  const navigate = useNavigate();
  const location = useLocation();
  // const [show, setShow] = useState("live");
  const typeWriterRef = useRef(null);
  const [loading, setLoading] = useState(true);
  const [pageNo, setPageNo] = useState(1);
  const [view, setView] = useState();
  const [feedList, setfeedList] = useState();
  const [callApi, setCallApi] = useState(1);

  useEffect(() => {
    // Store the original background image
    const originalBackgroundImage = document.body.style.backgroundImage;

    // Set the background image to none
    document.body.style.backgroundImage = `url(${image1})`;

    // Cleanup function to restore the original background image
    return () => {
      document.body.style.backgroundImage = originalBackgroundImage;
    };
  }, []);
  useEffect(() => {
    getrolePlay(window.location.pathname.slice(1));
  }, [pageNo, callApi, location]);

  const getrolePlay = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/feed/${id}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch");
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.isSuccess) {
          setfeedList(data.feedData);
          setView(data.model);
          if (data.model === "session") {
            addTempPerson();
          }
          if (
            (data.model === "live" && data.feedData.status !== "live") ||
            (data.model === "demo" && data.feedData.live !== "ready")
          ) {
            setTimeout(() => {
              setCallApi(callApi + 1);
            }, 5000);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
        // Handle error state here if needed
        setLoading(false); // Update loading state to indicate fetch failed
      });
  };

  const getPerson = (id) => {
    const requestOptions = {
      method: "GET",
      headers: {
        "x-api-key": process.env.REACT_APP_API_KEY,
      },
    };

    fetch(`https://embed.axv.ai/api/people/${id}`, requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch");
        }
        return response.json();
      })
      .then((data) => {
        if (data && data.isSuccess) {
          setDemo({ ...demo, person: data.peopleData, callSessionV: true });
        }
      })
      .catch((error) => {
        console.error("Error fetching data:", error.message);
        // Handle error state here if needed
        setLoading(false); // Update loading state to indicate fetch failed
      });
  };
  useEffect(() => {
    if (feedList && demo.person === undefined) {
      checkPerson();
    }
  }, [feedList]);
  const addTempPerson = () => {
    const oldP = JSON.parse(window.localStorage.getItem("persons"));

    if (oldP && oldP.length) {
      setDemo({
        ...demo,
        person: oldP[0],
      });
    }
  };
  const checkPerson = () => {
    const oldP = JSON.parse(window.localStorage.getItem("persons"));
    if (oldP) {
      let isFound = oldP.filter((f) => f.stream_id === feedList?._id);
      if (
        isFound &&
        isFound.length &&
        isFound[0]?.stream_id === feedList?._id
      ) {
        setDemo({
          ...demo,
          person: isFound[0],
        });
        getPerson(isFound[0]._id);
      }
    }
  };

  const channelV = (data) => {
    return (
      <div style={{ marginTop: "30px" }} className="public-page-c">
        <div className="container">
          <Row>
            {data &&
              data.map((m, mi) => {
                return (
                  <Col
                    lg={{ span: 8 }}
                    md={{ span: 8 }}
                    sm={{ span: 12 }}
                    xs={{ span: 24 }}
                    key={mi}
                    //   className="display-goal-st-s"
                    style={{ padding: "15px" }}
                  >
                    <div className="public-video-card">
                      <img src={m.thumbnail} alt="" />

                      <div className="public-top-c">
                        <b>{m.title}</b>
                      </div>
                      <div className="public-top-c2">{m.instruction}</div>
                      <div className="bottom-right-c">
                        <div
                          onClick={() => {
                            navigate(`/${m._id}`);
                            setfeedList();
                            setView();
                            setCallApi(callApi + 1);
                          }}
                          className="right-arrow-public"
                        >
                          <FaPlay />
                        </div>
                      </div>
                    </div>
                  </Col>
                );
              })}
          </Row>
        </div>
      </div>
    );
  };

  return loading ? (
    <div className="loader-video-img">
      <img src={logo} alt="" />
    </div>
  ) : feedList ? (
    <div>
      {demo.person ? (
        view === "session" ? (
          <WatchStatus row={feedList} />
        ) : view === "channel" ? (
          channelV(feedList)
        ) : view === "demo" ? (
          <DemoStatusLive row={feedList} />
        ) : null
      ) : view === "session" ? (
        <AddPerson
          row={feedList}
          text={feedList.button_1_text ? feedList.button_1_text : "Watch"}
          isAuto={true}
        />
      ) : (
        <PreeLoad view={view} row={feedList} />
      )}
    </div>
  ) : (
    <EmptyTyping />
  );
}
